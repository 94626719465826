import { MASK_CNPJ } from './mask-cnpj';
import { MASK_CPF } from './mask-cpf';

const LENGTH_CPF = 14;

export function MASK_CPF_CNPJ(input) {
    if (input && input.length > LENGTH_CPF) {
        return MASK_CNPJ;
    } else {
        return MASK_CPF;
    }
}
